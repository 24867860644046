import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { loginUser, loginSuccess, loginFailure, logoutUser, checkTokenExpiration } from '../actions/authActions';
import { jwtDecode } from 'jwt-decode';  // Corrected the import
import { login } from '../../apiService';  // Make sure the import path is correct

function* handleLoginUser(action) {
  try {
    const response = yield call(login, action.payload);
    const token = response.data.access_token;
    yield put(loginSuccess(token));
  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

function* handleCheckTokenExpiration() {
  const token = yield select((state) => state.auth.token);
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      yield put(logoutUser());
    }
  }
}

function* watchLoginUser() {
  yield takeEvery(loginUser.type, handleLoginUser);
}

function* watchCheckTokenExpiration() {
  yield takeEvery(checkTokenExpiration.type, handleCheckTokenExpiration);
}

function* rootSaga() {
  yield all([watchLoginUser(), watchCheckTokenExpiration()]);
}

export default rootSaga;
