import React, { createContext, useContext, useState } from 'react';

const UploadContext = createContext();

export const useUpload = () => useContext(UploadContext);

export const UploadProvider = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileType, setFileType] = useState('');
  const [url, setUrl] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <UploadContext.Provider value={{ selectedFiles, setSelectedFiles, fileType, setFileType, url, setUrl, tabIndex, setTabIndex }}>
      {children}
    </UploadContext.Provider>
  );
};
