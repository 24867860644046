import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, Text, Heading, Input, Button, Card, CardHeader, CardBody, CardFooter, Avatar, VStack, HStack } from '@chakra-ui/react';
import { getPrivateCollections } from '../apiService';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { setCollectionId } from '../store/actions/collectionActions';
import CreateKnowledgeBaseDialog from './CreateKnowledgeBaseDialog';

const KnowledgeBase = () => {
  const [collections, setCollections] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  
  let user = null;
  let fullName = '';

  if (token) {
    const decodedToken = jwtDecode(token);
    user = {
      loginId: decodedToken.LoginId,
      userName: decodedToken.UserName,
      fullName: decodedToken.FullName,
    };
    fullName = user.fullName;
  }

  useEffect(() => {
    fetchCollections();
  }, []);

  const fetchCollections = async () => {
    try {
      const response = await getPrivateCollections();
      setCollections(response.data);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

  const handleCollectionClick = (collection) => {
    const { collection_id, public: isPublic } = collection;
    dispatch(setCollectionId(collection_id));
    localStorage.setItem('collection_id', collection_id); // Save collection ID to local storage
    localStorage.setItem('is_public', isPublic); // Save public status to local storage
    navigate(`/knowledgebase/details/${collection_id}`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCollections = collections.filter(collection =>
    collection.collection_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box padding={8} bg="#232121" color="white" flex="1" display="flex" flexDirection="column" minHeight="calc(100vh - 3.6rem)">
      <Heading as="h2" size="xl" mb={4} paddingTop="4rem">Welcome back, {fullName}</Heading>
      <Box mb={6} display="flex" justifyContent="space-between" alignItems="center">
        <Input
          placeholder="Search"
          width="20rem"
          borderRadius="md"
          bg="rgb(0 0 0 / 17%)"
          color="white"
          _placeholder={{ color: 'gray.400' }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Button colorScheme="red" bg="red.600" _hover={{ bg: "red.700" }} onClick={() => setIsDialogOpen(true)}>
          + Create knowledge base
        </Button>
      </Box>
      <Box flex="1" overflowY="auto">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
          {filteredCollections.map((collection) => (
            <Card
              key={collection.collection_id}
              onClick={() => handleCollectionClick(collection)}
              cursor="pointer"
              bg="rgb(0 0 0 / 17%)"
              _hover={{ bg: "gray.800" }}
              color="white"
              borderRadius="md"
              height="14rem" // Fixed height
              display="flex"
              flexDirection="column"
              width="100%" // Ensure card takes full width of the grid column
            >
              <CardHeader>
                <HStack spacing={4}>
                  <Avatar size="md" name={collection.collection_name} />
                  <VStack align="start">
                    <Text fontSize="2xl" fontWeight="bold" isTruncated>{collection.collection_name}</Text>
                    <Text fontSize="sm" color="gray.400">Created by: {collection.created_by}</Text>
                  </VStack>
                </HStack>
              </CardHeader>
              <CardBody flex="1">
                <Text>{collection.document_count} Docs</Text>
              </CardBody>
              <CardFooter>
                <Text>{new Date(collection.created_at).toLocaleDateString()}</Text>
              </CardFooter>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
      <CreateKnowledgeBaseDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} fetchCollections={fetchCollections} />
    </Box>
  );
};

export default KnowledgeBase;
