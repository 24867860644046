import React, { useEffect } from 'react';
import { Box, Button, Input, FormControl, FormLabel, useToast, VStack, Text, InputGroup, InputLeftElement, FormErrorMessage, Link as ChakraLink, Center } from '@chakra-ui/react';
import { FaUserAlt, FaLock, FaMicrosoft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../store/actions/authActions';
import { validateEmailOrUsername } from '../utils/validators';
import degLogo from '../assets/images/deg.png';

const Login = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, register, formState: { errors } } = useForm();
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);

  useEffect(() => {
    if (token) {
      toast({
        title: 'Successfully logged in',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/conversation');
    } else if (error) {
      toast({
        title: 'Invalid username/email or password',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [token, error, toast, navigate]);

  const handleLogin = (values) => {
    dispatch(loginUser(values));
  };

  const handleMicrosoftLogin = () => {
    window.location.href = "http://localhost:5005/auth/login/microsoft";
      //  window.location.href = "https://geraltaiapi.rectitudecs.com/auth/login/microsoft";
  };

  return (
<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flex="1" bg="#232121" p={6}>
  <Box bg="rgba(0, 0, 0, 0.17)" p={6} rounded="md" shadow="md" w="full" maxW="4xl" display="flex" flexDirection={{ base: 'column', md: 'row' }} color="white">
    <Box flex="1" display="flex" justifyContent="center" alignItems="center" p={6}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          src={degLogo}
          alt="Domain Experts Logo"
          style={{ width: '8rem' }}
        />
        <Text paddingTop="3rem" textAlign="center">Welcome back! Please login to your account.</Text>
      </Box>
    </Box>
    <Box flex="1" p={6} display="flex" justifyContent="center" alignItems="center">
      <form onSubmit={handleSubmit(handleLogin)} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <VStack spacing={4} width="100%">
          <FormControl id="identifier" isRequired isInvalid={errors.identifier}>
            <FormLabel>Username or Email</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FaUserAlt color="gray.300" />} />
              <Input
                {...register('identifier', { required: 'Username or Email is required', validate: validateEmailOrUsername })}
                placeholder="Username or Email"
                bg="rgba(0, 0, 0, 0.17)"
                color="white"
                _placeholder={{ color: 'gray.400' }}
              />
            </InputGroup>
            <FormErrorMessage>{errors.identifier && errors.identifier.message}</FormErrorMessage>
          </FormControl>
          <FormControl id="password" isRequired isInvalid={errors.password}>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FaLock color="gray.300" />} />
              <Input
                type="password"
                {...register('password', { required: 'Password is required' })}
                placeholder="Password"
                bg="rgba(0, 0, 0, 0.17)"
                color="white"
                _placeholder={{ color: 'gray.400' }}
              />
            </InputGroup>
            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
          </FormControl>
          <Button type="submit" colorScheme="red" bg="red.600" _hover={{ bg: "red.700" }} width="full">
            Login
          </Button>
          <Button onClick={handleMicrosoftLogin} leftIcon={<FaMicrosoft />} colorScheme="blue" width="full">
            Login with Microsoft
          </Button>
        </VStack>
      </form>
    </Box>
  </Box>
</Box>



  );
};

export default Login;
