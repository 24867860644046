import { createReducer } from '@reduxjs/toolkit';
import { setCollectionId, setCollectionDetails } from '../actions/collectionActions';

const initialState = {
  collectionId: null,
  collectionDetails: {},
};

const collectionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCollectionId, (state, action) => {
      state.collectionId = action.payload;
    })
    .addCase(setCollectionDetails, (state, action) => {
      state.collectionDetails = action.payload;
    });
});

export { collectionReducer };
