import React from 'react';
import { Box, VStack, Flex, Button, IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { EditIcon, DeleteIcon, HamburgerIcon } from '@chakra-ui/icons';

const ConversationList = ({ conversations, fetchConversation, handleNewConversation, handleRename, handleDelete, collapsed }) => {
  return (
    <Box
      width={collapsed ? '0' : '19rem'}
      bg="#1c1c1c"
      overflow="hidden"
      transition="width 0.3s"
      // p={4}
      padding={collapsed ? "0rem" : "1rem"}
    >
      {!collapsed && (
        <VStack spacing={4}>
          <Button
            onClick={handleNewConversation}
            colorScheme="red"
            bg="red.600"
            _hover={{ bg: "red.700" }}
            width="full"
            color="white"
          >
            New Conversation
          </Button>
          {conversations.map((item) => (
            <Flex key={item.conversation_id} width="100%" alignItems="center">
              <Box flex="1">
                <Button
                  flex="1"
                  justifyContent="flex-start"
                  onClick={() => fetchConversation(item.conversation_id)}
                  whiteSpace="nowrap"
                  maxWidth="21rem"
                  textAlign="left"
                  bg="#1c1c1c"
                  color="white"
                  _hover={{ bg: "gray.800" }}
                >
                  {item.first_question.length > 20 ? `${item.first_question.substring(0, 20)}...` : item.first_question}
                </Button>
              </Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  variant="outline"
                  aria-label="Options"
                  color="white"
                  bg="rgb(0 0 0 / 17%)"
                  _hover={{ bg: "gray.800" }}
                />
                <MenuList bg="#1c1c1c" borderColor="gray.700">
                  <MenuItem icon={<EditIcon />} onClick={() => handleRename(item.conversation_id)} _hover={{ bg: "red.600", color: "rgba(0, 0, 0, 0.9)" }}  background={"#1c1c1c"} color={"white"}>
                    Rename
                  </MenuItem>
                  <MenuItem icon={<DeleteIcon />} onClick={() => handleDelete(item.conversation_id)} _hover={{ bg: "red.600", color: "rgba(0, 0, 0, 0.9)"}} background={"#1c1c1c"} color={"white"}>
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default ConversationList;
