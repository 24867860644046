import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Flex, Button, Box, Menu, MenuButton, MenuList, MenuItem, Avatar } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../store/actions/authActions';
import { jwtDecode } from 'jwt-decode';
import degLogo from '../assets/images/deg.png';
import degname from '../assets/images/degname.png';
const Navbar = ({ collapsed, toggleCollapse }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  let user = null;
  let avatarLetters = "";

  if (token) {
    const decodedToken = jwtDecode(token);
    user = decodedToken.sub;
    avatarLetters = `${user.firstname[0]}${user.lastname[0]}`;
  }

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  return (
    <Flex as="nav" align="center" justify="space-between" wrap="wrap" padding="1rem" bg="#2e2e2e" color="white">
      <Flex align="center" mr={5}>
        <img src={degLogo} alt="Domain Experts Logo" width="30" height="30" />
        <Box as="span" ml={2} fontWeight="bold" fontSize="lg">
          <img
            src={degname}
            alt="Domain Experts AI"
            style={{ width: '12rem' }}
          />
        </Box>
      </Flex>
      <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center">
        <Button variant="link" color="white" mr={4}>
          <Link to="/conversation" style={{ color: 'white', textDecoration: 'none' }}>Conversation</Link>
        </Button>
        {token ? (
          <Menu>
            <MenuButton as={Avatar} size="sm" name={user ? `${user.firstname} ${user.lastname}` : "User Profile"}>
              {avatarLetters}
            </MenuButton>
            <MenuList bg="rgb(0 0 0 / 17%)" color="white">
              <MenuItem _hover={{ bg: "red.600", color: "white" }} onClick={() => navigate('/profile')}>Profile</MenuItem>
              <MenuItem _hover={{ bg: "red.600", color: "white" }} onClick={() => navigate('/edit-profile')}>Edit Profile</MenuItem>
              <MenuItem _hover={{ bg: "red.600", color: "white" }} onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <Button variant="link" color="white" mr={4}>
            <Link to="/login" style={{ color: 'white', textDecoration: 'none' }}>Login</Link>
          </Button>
        )}
      </Box>
    </Flex>
  );
};

export default Navbar;
