import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../store/actions/authActions';

const AuthCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      dispatch(loginSuccess(token)); // Set the token in Redux
      localStorage.setItem('token', token); // Store token in localStorage
      navigate('/conversation');
    } else {
      navigate('/login');
    }
  }, [dispatch, navigate]);

  return <div>Logging in...</div>;
};

export default AuthCallback;
