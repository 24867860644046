import React, { useState } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Button, FormControl, FormLabel, Input, Checkbox, useToast
} from '@chakra-ui/react';
import { createPublicCollection, createPrivateCollection } from '../apiService';

const CreateKnowledgeBaseDialog = ({ isOpen, onClose, fetchCollections }) => {
  const [collectionName, setCollectionName] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const toast = useToast();

  const handleCreate = async () => {
    try {
      const payload = { collection_name: collectionName, public: isPublic };
      if (isPublic) {
        await createPublicCollection(payload);
      } else {
        await createPrivateCollection(payload);
      }
      toast({
        title: 'Collection created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchCollections();
      onClose();
    } catch (error) {
      toast({
        title: 'Error creating collection',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="linear-gradient(145deg, #2e2e2e, #1e1e1e)" color="white">
        <ModalHeader>Create Knowledge Base</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Collection Name</FormLabel>
            <Input
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
              bg="#232121"
              color="white"
              _placeholder={{ color: 'gray.400' }}
            />
          </FormControl>
          <FormControl mt={4}>
            <Checkbox
              isChecked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
              colorScheme="red"
            >
              Make Public
            </Checkbox>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} color="white" _hover={{ bg: "gray.800" }}>Cancel</Button>
          <Button colorScheme="red" onClick={handleCreate} ml={3}>Create</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateKnowledgeBaseDialog;
