import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { getBotTokenDetails, updateBotToken } from "../apiService";
import { useParams } from "react-router-dom";

const BotDetails = () => {
  const { botToken } = useParams(); // Get bot token from route params
  const [botDetails, setBotDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [botName, setBotName] = useState("");
  const [collectionIds, setCollectionIds] = useState("");
  const [prompt, setPrompt] = useState("");
  const toast = useToast();

  useEffect(() => {
    const fetchBotDetails = async () => {
      try {
        const response = await getBotTokenDetails(botToken);
        setBotDetails(response.data);
        setBotName(response.data.bot_name);
        setCollectionIds(response.data.collection_ids.join(", "));
        setPrompt(response.data.prompt);
      } catch (error) {
        console.error("Error fetching bot details:", error);
        toast({
          title: "Error",
          description: "Failed to fetch bot details.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchBotDetails();
  }, [botToken, toast]);

  const handleUpdateBot = async () => {
    try {
      await updateBotToken({
        bot_token: botToken,
        bot_name: botName,
        collection_ids: collectionIds.split(",").map((id) => id.trim()),
        prompt: prompt,
      });
      toast({
        title: "Success",
        description: "Bot updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating bot:", error);
      toast({
        title: "Error",
        description: "Failed to update bot details.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!botDetails) {
    return <p>Loading...</p>;
  }

  return (
    <Box
      padding={8}
      bg="#232121"
      color="white"
      minHeight="calc(100vh - 3.6rem)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Card
        width="100%"
        maxWidth="4xl"
        bg="rgb(0 0 0 / 17%)"
        borderRadius="md"
        boxShadow="md"
      >
        <CardHeader>
          <Heading size="lg" color="white">
            Bot Details
          </Heading>
        </CardHeader>
        <CardBody>
          <VStack spacing={4} align="stretch">
            <FormControl isReadOnly mb={4}>
              <FormLabel color="white">Bot Token</FormLabel>
              <Input
                value={botDetails.bot_token}
                isReadOnly
                bg="#2e2e2e"
                color="white"
              />
            </FormControl>

            <FormControl mb={4} isDisabled={!isEditing}>
              <FormLabel color="white">Bot Name</FormLabel>
              <Input
                value={botName}
                onChange={(e) => setBotName(e.target.value)}
                bg="#2e2e2e"
                color="white"
                _disabled={{ bg: "#2e2e2e" }}
              />
            </FormControl>

            <FormControl mb={4} isDisabled={!isEditing}>
              <FormLabel color="white">
                Collection IDs (comma-separated)
              </FormLabel>
              <Input
                value={collectionIds}
                onChange={(e) => setCollectionIds(e.target.value)}
                bg="#2e2e2e"
                color="white"
                _disabled={{ bg: "#2e2e2e" }}
              />
            </FormControl>

            <FormControl mb={4} isDisabled={!isEditing}>
              <FormLabel color="white">Prompt</FormLabel>
              <Textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                bg="#2e2e2e"
                color="white"
                _disabled={{ bg: "#2e2e2e" }}
              />
            </FormControl>
          </VStack>
        </CardBody>

        <CardFooter display="flex" justifyContent="flex-end">
          <Button
            colorScheme="red"
            onClick={() => {
              if (isEditing) {
                handleUpdateBot();
              } else {
                setIsEditing(true);
              }
            }}
          >
            {isEditing ? "Update" : "Edit"}
          </Button>
          {isEditing && (
            <Button
              variant="ghost"
              onClick={() => setIsEditing(false)}
              ml={3}
              color="white"
              _hover={{ bg: "gray.800" }}
            >
              Cancel
            </Button>
          )}
        </CardFooter>
      </Card>
    </Box>
  );
};

export default BotDetails;
