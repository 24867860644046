import { createAction } from '@reduxjs/toolkit';

export const loginUser = createAction('auth/loginUser', (credentials) => ({
  payload: credentials,
}));

export const loginSuccess = createAction('auth/loginSuccess', (token) => ({
  payload: token, // Ensure you're passing the full token as received
}));

export const loginFailure = createAction('auth/loginFailure', (error) => ({
  payload: error,
}));

export const logoutUser = createAction('auth/logoutUser');

export const checkTokenExpiration = createAction('auth/checkTokenExpiration');
