import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import {
  getAllConversations,
  getConversation,
  searchWithConversation,
  searchWithBotToken,
  deleteConversation,
  getPrivateCollections,
  searchPublicKnowledgeBase,
} from "../../apiService";
import ConversationList from "./ConversationList";
import MessagesDisplay from "./MessagesDisplay";
import MessageInput from "./MessageInput";
import { useLocation } from "react-router-dom";

const Conversation = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(
    localStorage.getItem("selectedConversation") || null
  );
  const [collectionIds, setCollectionIds] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [botToken, setBotToken] = useState(null); // For bot token-based conversation
  const toast = useToast();
  const token = localStorage.getItem("token");

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const botTokenFromURL = params.get("bot_token");

    if (botTokenFromURL) {
      setBotToken(botTokenFromURL);
    } else if (token) {
      fetchConversations();
      fetchCollectionIds();
    }

    if (selectedConversation) {
      fetchConversation(selectedConversation);
    }
  }, [selectedConversation, token, location]);

  useEffect(() => {
    // Listen for logout event and clear local storage
    const handleStorageEvent = (event) => {
      if (event.key === "token" && event.newValue === null) {
        localStorage.removeItem("selectedConversation");
        setSelectedConversation(null);
      }
    };
    window.addEventListener("storage", handleStorageEvent);
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);

  const fetchConversations = async () => {
    try {
      const response = await getAllConversations();
      setConversations(response.data);
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  };

  const fetchCollectionIds = async () => {
    try {
      const response = await getPrivateCollections();
      const ids = response.data
        .map((collection) => collection.collection_id)
        .join(",");
      setCollectionIds(ids);
    } catch (error) {
      console.error("Error fetching collection IDs:", error);
    }
  };

  const fetchConversation = async (conversation_id) => {
    try {
      const response = await getConversation(conversation_id);
      if (response.data) {
        setMessages(response.data.messages);
        setSelectedConversation(conversation_id);
        localStorage.setItem("selectedConversation", conversation_id);
      } else {
        console.error("No data in response for fetchConversation");
      }
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  };

  const handleSendMessage = async () => {
    if (currentMessage.trim()) {
      setIsSending(true);
      const userMessage = { content: currentMessage, role: "user" };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setCurrentMessage("");

      try {
        let response;

        // If bot token is present, use it for the conversation
        if (botToken) {
          response = await searchWithBotToken({
            query: currentMessage.trim(),
            bot_token: botToken,
          });
        } else if (!token) {
          response = await searchPublicKnowledgeBase(currentMessage.trim());
        } else if (!selectedConversation) {
          response = await searchWithConversation(
            collectionIds,
            "",
            currentMessage.trim()
          );
          const newConversationId = response.data?.conversation_id;
          if (newConversationId) {
            setSelectedConversation(newConversationId);
            setConversations((prevConversations) => [
              ...prevConversations,
              {
                conversation_id: newConversationId,
                first_question: currentMessage,
              },
            ]);
            localStorage.setItem("selectedConversation", newConversationId);
          }
        } else {
          response = await searchWithConversation(
            collectionIds,
            selectedConversation,
            currentMessage.trim()
          );
        }

        setIsTyping(true);
        setTimeout(() => {
          const botMessage = {
            content: response.data?.response || "Error: No response from bot",
            role: "system",
          };
          setMessages((prevMessages) => [...prevMessages, botMessage]);
          setIsTyping(false);
          setIsSending(false);
        }, 1000);
      } catch (error) {
        console.error("Error sending message:", error);
        setIsSending(false);
        setIsTyping(false);
      }
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleRename = (id) => {
    const newName = prompt("Enter new name:");
    if (newName) {
      setConversations((prevConversations) =>
        prevConversations.map((convo) =>
          convo.conversation_id === id ? { ...convo, name: newName } : convo
        )
      );
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteConversation(id);
      setConversations((prevConversations) =>
        prevConversations.filter((convo) => convo.conversation_id !== id)
      );
      if (id === selectedConversation) {
        setMessages([]);
        setSelectedConversation(null);
        localStorage.removeItem("selectedConversation");
      }
      toast({
        title: "Conversation deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting conversation:", error);
      toast({
        title: "Error deleting conversation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNewConversation = () => {
    setMessages([]);
    setSelectedConversation(null);
    localStorage.removeItem("selectedConversation");
    toast({
      title: "New conversation started.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex
      height="calc(100vh - 3.6rem)"
      paddingTop={token || botToken ? "4.1rem" : "0"}
      backgroundColor="#232121"
      overflow="hidden"
      flexDirection={{ base: "column", md: "row" }}
    >
      {(token || botToken) && !botToken && (
        <ConversationList
          conversations={conversations}
          fetchConversation={fetchConversation}
          handleNewConversation={handleNewConversation}
          handleRename={handleRename}
          handleDelete={handleDelete}
          collapsed={collapsed}
        />
      )}
      <Box flex="1" padding={token || botToken ? 4 : 0}>
        <Card height="100%" bg="#232121" color="white">
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              {token && !botToken && (
                <IconButton
                  onClick={toggleCollapse}
                  icon={collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                  variant="outline"
                  aria-label="Toggle sidebar"
                  color="white"
                  bg="rgb(0 0 0 / 17%)"
                  _hover={{ bg: "gray.800" }}
                />
              )}
              Chat
            </Flex>
          </CardHeader>
          <CardBody display="flex" flexDirection="column" overflowY="auto">
            <MessagesDisplay messages={messages} isTyping={isTyping} />
            <MessageInput
              currentMessage={currentMessage}
              setCurrentMessage={setCurrentMessage}
              handleSendMessage={handleSendMessage}
              isSending={isSending}
            />
          </CardBody>
        </Card>
      </Box>
    </Flex>
  );
};

export default Conversation;
