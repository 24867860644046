import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Register from "./components/Register";
import Conversation from "./components/Conversation";
import Dock from "./components/Dock";
import Footer from "./components/Footer";
import { checkTokenExpiration } from "./store/actions/authActions";
import KnowledgeBase from "./components/KnowledgeBase";
import KnowledgeBaseDetails from "./components/KnowledgeBaseDetails";
import Bots from "./components/Bots";
import AuthCallback from "./components/AuthCallback";
import BotDetails from "./components/BotDetails";
import BotEmbedConversation from "./components/BotEmbed"; // Import the BotEmbed component

const ProtectedRoute = ({ children }) => {
  const token = useSelector((state) => state.auth.token);
  return token ? children : <Navigate to="/login" />;
};

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    dispatch(checkTokenExpiration());
  }, [dispatch]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <ChakraProvider>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        {/* Only show Navbar and Footer if not using the bot embed route */}
        <Routes>
          {/* Check if the path is '/embed/conversation' and hide Navbar/Footer */}
          <Route
            path="/embed/conversation"
            element={<BotEmbedConversation />}
          />

          <Route
            path="*"
            element={
              <>
                {!token && (
                  <Navbar
                    collapsed={collapsed}
                    toggleCollapse={toggleCollapse}
                  />
                )}
                {token && <Dock />}
                <Box as="main" flex="1" display="flex" flexDirection="column">
                  <Routes>
                    <Route
                      path="/login"
                      element={
                        token ? <Navigate to="/conversation" /> : <Login />
                      }
                    />
                    <Route
                      path="/register"
                      element={
                        <ProtectedRoute>
                          <Register />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/conversation"
                      element={<Conversation collapsed={collapsed} />}
                    />
                    <Route
                      path="/knowledgebase"
                      element={
                        <ProtectedRoute>
                          <KnowledgeBase />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/knowledgebase/details/:collectionId"
                      element={<KnowledgeBaseDetails />}
                    />
                    <Route
                      path="/graph"
                      element={
                        <ProtectedRoute>
                          <div>Graph</div>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/file-management"
                      element={
                        <ProtectedRoute>
                          <div>File Management</div>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <ProtectedRoute>
                          <div>Profile</div>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/edit-profile"
                      element={
                        <ProtectedRoute>
                          <div>Edit Profile</div>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/bots"
                      element={
                        <ProtectedRoute>
                          <Bots />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/bots/:botToken"
                      element={
                        <ProtectedRoute>
                          <BotDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/auth/callback" element={<AuthCallback />} />
                    <Route
                      path="/"
                      element={
                        <Navigate to={token ? "/conversation" : "/login"} />
                      }
                    />
                  </Routes>
                </Box>
                <Footer />
              </>
            }
          />
        </Routes>
      </Box>
    </ChakraProvider>
  );
};

export default App;
