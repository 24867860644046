import React from 'react';
import {
  Table, Thead, Tbody, Tr, Th, Td, HStack, Button, IconButton
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const isPublic = localStorage.getItem('is_public') === 'true'; // Get public status from local storage

const DocumentTable = ({ documents, handleDelete }) => {
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>File Name</Th>
          <Th>Added By</Th>
          {/* <Th>Upload Date</Th> */}
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {documents.map((doc) => (
          <Tr key={doc.document_id}>
            <Td>{doc.file_name}</Td>
            <Td>{doc.added_by}</Td>
            {/* <Td>{new Date(doc.created_at).toLocaleDateString()}</Td> */}
            <Td>
              <HStack spacing={2}>
                <Button size="sm" isDisabled colorScheme="red">Edit</Button>
                {!isPublic && (
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleDelete(doc.document_id)}
                    variant="ghost"
                    aria-label="Delete document"
                    size="sm"
                    color="red.600"
                    _hover={{ color: "white", bg: "red.600" }}
                  />
                )}
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default DocumentTable;
