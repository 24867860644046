import React, { useEffect, useRef } from 'react';
import { VStack, HStack, Box, Avatar } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MessagesDisplay = ({ messages, isTyping }) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const renderMessages = () => {
    console.log("Rendering messages:", messages); // Debug log

    let groupedMessages = [];
    let currentGroup = [];

    messages.forEach((msg, index) => {
      if (index === 0 || msg.role !== messages[index - 1].role) {
        if (currentGroup.length) {
          groupedMessages.push(currentGroup);
        }
        currentGroup = [msg];
      } else {
        currentGroup.push(msg);
      }
    });

    if (currentGroup.length) {
      groupedMessages.push(currentGroup);
    }

    console.log("Grouped messages:", groupedMessages); // Debug log

    return groupedMessages.map((group, index) => (
      <VStack key={index} alignSelf={group[0].role === 'user' ? 'flex-end' : 'flex-start'} width="100%" paddingBottom={"6rem"}>
        <HStack
          alignSelf={group[0].role === 'user' ? 'flex-end' : 'flex-start'}
          bg={group[0].role === 'user' ? 'red.600' : 'linear-gradient(145deg, #232121, #1e1e1e)'}
          borderRadius="md"
          padding={3}
          maxWidth="80%"
          alignItems="center"
          color="white"
        >
          {index === 0 || (index > 0 && messages[index - 1].role !== group[0].role) ? (
            <Avatar name={group[0].role === 'user' ? 'User' : 'Bot'} src="https://via.placeholder.com/40" size="sm" mr={2} />
          ) : (
            <Box width="40px" height="40px" mr={2} />
          )}
          <Box>
            {group.map((msg, idx) => (
              <ReactMarkdown key={idx} remarkPlugins={[remarkGfm]}>{msg.content}</ReactMarkdown>
            ))}
          </Box>
        </HStack>
      </VStack>
    ));
  };

  return (
    <VStack flex="1" overflowY="scroll" spacing={4} marginBottom={4} bg="#232121">
      {renderMessages()}
      {isTyping && (
        <HStack alignSelf="flex-start" bg="#232121" borderRadius="md" padding={3} maxWidth="80%" alignItems="center" color="white">
          <Avatar name="Bot" src="https://via.placeholder.com/40" size="sm" mr={2} />
          <Box>
            <Box>...</Box>
          </Box>
        </HStack>
      )}
      <div ref={messagesEndRef} />
    </VStack>
  );
};

export default MessagesDisplay;
