import React, { useEffect, useState } from 'react';
import { register } from '../apiService';
import { Box, Button, Input, FormControl, FormLabel, useToast, VStack, InputGroup, InputLeftElement, FormErrorMessage, Text, Link as ChakraLink, SimpleGrid, Progress } from '@chakra-ui/react';
import { FaUserAlt, FaEnvelope, FaLock, FaUserTag } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateEmail, validatePasswordStrength } from '../utils/validators';
import zxcvbn from 'zxcvbn';

const Register = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { handleSubmit, register: registerInput, formState: { errors }, watch, trigger } = useForm();
  const password = watch('password', '');
  const [passwordStrength, setPasswordStrength] = useState(0);

  useEffect(() => {
    setPasswordStrength(zxcvbn(password || '').score);
  }, [password]);

  useEffect(() => {
    trigger('confirmPassword');
  }, [password, trigger]);

  const handleRegister = async (values) => {
    try {
      const response = await register(values);
      if (response.status === 201) {
        toast({
          title: 'User successfully registered',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/login');
      }
    } catch (error) {
      console.error('Error registering', error);
      toast({
        title: 'Username or email already exists',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bg="#2e2e2e" p={6}>
      <Box bg="rgb(0 0 0 / 17%)" p={6} rounded="md" shadow="md" w="full" maxW="4xl" display="flex" color="white">
        <Box flex="1" display="flex" justifyContent="center" alignItems="center">
          <Box>
            <Text fontSize="3xl" fontWeight="bold" mb={6}>Register Here</Text>
            <Text>Join us today and enjoy our services!</Text>
          </Box>
        </Box>
        <Box flex="1">
          <form onSubmit={handleSubmit(handleRegister)}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl id="username" isRequired isInvalid={errors.username}>
                <FormLabel>Username</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FaUserAlt color="gray.300" />} />
                  <Input
                    {...registerInput('username', { required: 'Username is required' })}
                    placeholder="Username"
                    onBlur={() => trigger('username')}
                    bg="rgb(0 0 0 / 17%)"
                    color="white"
                    _placeholder={{ color: 'gray.400' }}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="email" isRequired isInvalid={errors.email}>
                <FormLabel>Email</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FaEnvelope color="gray.300" />} />
                  <Input
                    type="email"
                    {...registerInput('email', {
                      required: 'Email is required',
                      validate: validateEmail
                    })}
                    placeholder="Email"
                    onBlur={() => trigger('email')}
                    bg="rgb(0 0 0 / 17%)"
                    color="white"
                    _placeholder={{ color: 'gray.400' }}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isRequired isInvalid={errors.password}>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FaLock color="gray.300" />} />
                  <Input
                    type="password"
                    {...registerInput('password', {
                      required: 'Password is required',
                      validate: validatePasswordStrength
                    })}
                    placeholder="Password"
                    onBlur={() => trigger('password')}
                    bg="rgb(0 0 0 / 17%)"
                    color="white"
                    _placeholder={{ color: 'gray.400' }}
                  />
                </InputGroup>
                <Progress
                  mt={2}
                  value={passwordStrength * 25}
                  size="sm"
                  colorScheme={
                    passwordStrength === 0 ? 'red' :
                    passwordStrength === 1 ? 'orange' :
                    passwordStrength === 2 ? 'yellow' :
                    passwordStrength === 3 ? 'green' :
                    'teal'
                  }
                />
                <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="confirm-password" isRequired isInvalid={errors.confirmPassword}>
                <FormLabel>Confirm Password</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FaLock color="gray.300" />} />
                  <Input
                    type="password"
                    {...registerInput('confirmPassword', {
                      required: 'Confirm Password is required',
                      validate: value => value === password || 'Passwords do not match'
                    })}
                    placeholder="Confirm Password"
                    onBlur={() => trigger('confirmPassword')}
                    bg="rgb(0 0 0 / 17%)"
                    color="white"
                    _placeholder={{ color: 'gray.400' }}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="firstname" isRequired isInvalid={errors.firstname}>
                <FormLabel>First Name</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FaUserTag color="gray.300" />} />
                  <Input
                    {...registerInput('firstname', { required: 'First name is required' })}
                    placeholder="First Name"
                    onBlur={() => trigger('firstname')}
                    bg="rgb(0 0 0 / 17%)"
                    color="white"
                    _placeholder={{ color: 'gray.400' }}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.firstname && errors.firstname.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="lastname" isRequired isInvalid={errors.lastname}>
                <FormLabel>Last Name</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FaUserTag color="gray.300" />} />
                  <Input
                    {...registerInput('lastname', { required: 'Last name is required' })}
                    placeholder="Last Name"
                    onBlur={() => trigger('lastname')}
                    bg="rgb(0 0 0 / 17%)"
                    color="white"
                    _placeholder={{ color: 'gray.400' }}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.lastname && errors.lastname.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="role" isRequired isInvalid={errors.role}>
                <FormLabel>Role</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<FaUserTag color="gray.300" />} />
                  <Input
                    {...registerInput('role', { required: 'Role is required' })}
                    placeholder="Role"
                    onBlur={() => trigger('role')}
                    bg="rgb(0 0 0 / 17%)"
                    color="white"
                    _placeholder={{ color: 'gray.400' }}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.role && errors.role.message}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <VStack spacing={4} mt={4}>
              <Button type="submit" colorScheme="red" bg="red.600" _hover={{ bg: "red.700" }} width="full">
                Register
              </Button>
              <Text>Already have an account? <ChakraLink color="red.500" onClick={() => navigate('/login')}>Login here</ChakraLink></Text>
            </VStack>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
