import React, { useCallback, useState } from 'react';
import {
  Button, FormControl, FormLabel, Modal,
  ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Box, Text, IconButton, Spinner
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { CloseIcon } from '@chakra-ui/icons';

const PublicUploadDialog = ({ isOpen, onClose, handleFileChange, handleUpload, selectedFiles, removeFile }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    handleFileChange({ target: { files: acceptedFiles } });
  }, [handleFileChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/json': ['.json'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
      'text/html': ['.html'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    maxSize: 10485760 // 10MB file size limit
  });

  const handleUploadClick = async () => {
    setIsProcessing(true);
    await handleUpload();
    setIsProcessing(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#232121" color="white">
        <ModalHeader>Upload Public Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>File</FormLabel>
            <Box
              {...getRootProps()}
              border="2px dashed"
              borderColor={isDragActive ? 'red.300' : 'gray.300'}
              padding={4}
              textAlign="center"
              cursor="pointer"
              borderRadius="md"
              bg="#2e2e2e"
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Text>Drop the files here...</Text>
              ) : (
                <Text>Drag 'n' drop some files here, or click to select files</Text>
              )}
            </Box>
            {selectedFiles.length > 0 && (
              <Box mt={4}>
                {selectedFiles.map((file, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="md"
                    mt={2}
                    bg="#2e2e2e"
                  >
                    <Text>{file.name}</Text>
                    <IconButton
                      icon={<CloseIcon />}
                      onClick={() => removeFile(index)}
                      size="sm"
                      variant="ghost"
                      aria-label="Remove file"
                      color="white"
                      _hover={{ color: "red.600" }}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} color="white" _hover={{ bg: "gray.800" }}>Cancel</Button>
          <Button colorScheme="red" onClick={handleUploadClick} ml={3} isLoading={isProcessing}>
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PublicUploadDialog;
