import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Text, Button, Input, Heading, useToast
} from '@chakra-ui/react';
import { getDocumentsByCollection, deleteDocument, uploadDocument, uploadPublicKnowledgeBase } from '../apiService';
import UploadDialog from './UploadDialog';
import DocumentTable from './DocumentTable';
import PublicUploadDialog from './PublicUploadDialog';
import { UploadProvider, useUpload } from './UploadContext';

const KnowledgeBaseDetails = () => {
  const { collectionId } = useParams();
  const { selectedFiles, setSelectedFiles, fileType, setFileType, url, setUrl, tabIndex, setTabIndex } = useUpload();
  const [documents, setDocuments] = useState([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isPublicUploadModalOpen, setIsPublicUploadModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const toast = useToast();
  const isPublic = localStorage.getItem('is_public') === 'true'; // Get public status from local storage

  useEffect(() => {
    fetchDocuments();
  }, [collectionId]);

  const fetchDocuments = async () => {
    try {
      const response = await getDocumentsByCollection(collectionId);
      setDocuments(response.data);
    } catch (error) {
      toast({
        title: 'Error fetching documents',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (documentId) => {
    try {
      await deleteDocument(documentId);
      setDocuments(prevDocuments =>
        prevDocuments.filter(doc => doc.document_id !== documentId)
      );
      toast({
        title: 'Document deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed to delete document",
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpload = async () => {
    if (tabIndex === 0 && selectedFiles.length === 0) {
      toast({
        title: 'No file selected',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    if (tabIndex === 1 && !url) {
      toast({
        title: 'No URL provided',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    const formData = new FormData();
    if (tabIndex === 0) {
      for (const file of selectedFiles) {
        formData.append('file', file);
        const fileExtension = file.name.split('.').pop();
        setFileType(fileExtension);
        formData.append('file_type', fileExtension); // Automatically set file type
      }
    } else {
      formData.append('url', url);
      formData.append('file_type', fileType); // Use selected file type for URL/API
    }
    formData.append('collection_id', collectionId);
  
    console.log('FormData entries:');
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }
  
    try {
      await uploadDocument(formData);
      toast({
        title: 'Document uploaded successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsUploadModalOpen(false);
      setSelectedFiles([]);
      setUrl('');
      fetchDocuments();
    } catch (error) {
      toast({
        title: 'Error uploading document',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  

  const handlePublicUpload = async () => {
    if (selectedFiles.length === 0) {
      toast({
        title: 'No file selected',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    for (const file of selectedFiles) {
      formData.append('file', file);
    }
    formData.append('collection_id', collectionId);

    console.log('Public FormData:', formData.get('file'), formData.get('collection_id'));

    try {
      await uploadPublicKnowledgeBase(formData);
      toast({
        title: 'Document uploaded successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsPublicUploadModalOpen(false);
      setSelectedFiles([]);
      fetchDocuments();
    } catch (error) {
      toast({
        title: 'Error uploading document',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Ensure only one file is selected
    if (file) {
      setSelectedFiles([file]); // Update state with the selected file
      const fileExtension = file.name.split('.').pop();
      setFileType(fileExtension);
    }
  };

  const removeFile = index => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const openUploadModal = () => setIsUploadModalOpen(true);
  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
    setSelectedFiles([]);
    setUrl('');
  };

  const openPublicUploadModal = () => setIsPublicUploadModalOpen(true);
  const closePublicUploadModal = () => {
    setIsPublicUploadModalOpen(false);
    setSelectedFiles([]);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredDocuments = documents.filter(doc =>
    doc.file_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box padding={8} paddingTop={"4.1rem"} minHeight="100vh" bg="#232121" color="white">
      <Heading as="h2" size="xl" mb={4}>Dataset</Heading>
      <Text mb={6}>Questions and answers can only be answered after the parsing is successful.</Text>
      <Box mb={6} display="flex" justifyContent="space-between">
        <Button colorScheme="red" bg="red.600" _hover={{ bg: "red.700" }}>Bulk</Button>
        <Input
          placeholder="Search your files"
          width="300px"
          value={searchQuery}
          onChange={handleSearch}
          bg="#232121"
          color="white"
          _placeholder={{ color: 'gray.400' }}
        />
        {!isPublic && <Button colorScheme="red" bg="red.600" _hover={{ bg: "red.700" }} onClick={openUploadModal}>+ Add file</Button>}
        {isPublic && <Button colorScheme="green" bg="green.600" _hover={{ bg: "green.700" }} onClick={openPublicUploadModal}>+ Add Public Document</Button>}
      </Box>
      <DocumentTable documents={filteredDocuments} handleDelete={handleDelete} />
      <UploadDialog
        key={`upload-${isUploadModalOpen}`} // Ensure unique keys
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        removeFile={removeFile}
      />
      <PublicUploadDialog
        key={`public-upload-${isPublicUploadModalOpen}`} // Ensure unique keys
        isOpen={isPublicUploadModalOpen}
        onClose={closePublicUploadModal}
        handleFileChange={handleFileChange}
        handleUpload={handlePublicUpload}
        selectedFiles={selectedFiles}
        removeFile={removeFile}
      />
    </Box>
  );
};

const KnowledgeBaseDetailsWrapper = () => (
  <UploadProvider>
    <KnowledgeBaseDetails />
  </UploadProvider>
);

export default KnowledgeBaseDetailsWrapper;
