import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  Box,
  Text,
  VStack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import {
  updateBotToken,
  generateBotToken,
  getPrivateCollections,
} from "../apiService"; // Import API calls

const BotDialog = ({
  isOpen,
  onClose,
  isEditMode,
  selectedBotDetails,
  fetchBots,
}) => {
  const [botName, setBotName] = useState("");
  const [selectedCollections, setSelectedCollections] = useState([]); // Store selected collection objects
  const [availableCollections, setAvailableCollections] = useState([]); // All available collections
  const [searchTerm, setSearchTerm] = useState("");
  const [prompt, setPrompt] = useState(
    "You are a helpful assistant that only provides answers based on the provided data. According to the following context, answer the user's query accurately."
  ); // Default prompt
  const [showDropdown, setShowDropdown] = useState(false); // Dropdown visibility control
  const [isCollectionError, setIsCollectionError] = useState(false); // Error state for empty collections
  const inputRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    if (isEditMode && selectedBotDetails) {
      setBotName(selectedBotDetails.bot_name);
      const matchedCollections = availableCollections.filter((collection) =>
        selectedBotDetails.collection_ids.includes(collection.collection_id)
      );
      setSelectedCollections(matchedCollections);
      setPrompt(
        selectedBotDetails.prompt ||
          "You are a helpful assistant that only provides answers based on the provided data. According to the following context, answer the user's query accurately."
      );
    } else {
      setBotName("");
      setSelectedCollections([]);
      setPrompt(
        "You are a helpful assistant that only provides answers based on the provided data. According to the following context, answer the user's query accurately."
      );
    }
  }, [isEditMode, selectedBotDetails, availableCollections]);

  useEffect(() => {
    fetchCollections();
  }, []);

  const fetchCollections = async () => {
    try {
      const response = await getPrivateCollections();
      setAvailableCollections(response.data);
    } catch (error) {
      console.error("Error fetching collections:", error);
    }
  };

  const handleSelectCollection = (collection) => {
    if (
      !selectedCollections.some(
        (c) => c.collection_id === collection.collection_id
      )
    ) {
      setSelectedCollections([...selectedCollections, collection]); // Add full collection object (ID and name)
      setIsCollectionError(false); // Remove error if user selects a collection
    }
    setSearchTerm(""); // Clear search term after selection
  };

  const handleRemoveCollection = (collectionId) => {
    setSelectedCollections(
      selectedCollections.filter((c) => c.collection_id !== collectionId)
    ); // Remove collection
  };

  const handleSubmit = async () => {
    if (selectedCollections.length === 0) {
      setIsCollectionError(true); // Show error if no collections are selected
      toast({
        title: "Error",
        description: "Please select at least one collection.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const payload = {
      bot_name: botName,
      collection_ids: selectedCollections.map((c) => c.collection_id), // Send IDs, not names
      prompt,
    };

    try {
      if (isEditMode) {
        payload.bot_token = selectedBotDetails.bot_token;
        await updateBotToken(payload);
      } else {
        await generateBotToken(payload);
      }
      fetchBots(); // Refetch bots list after update/create
      onClose();
    } catch (error) {
      console.error("Error updating/creating bot:", error);
    }
  };

  const handleInputClick = () => {
    setShowDropdown(true);
  };

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowDropdown(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [showDropdown]);

  const filteredCollections = availableCollections.filter(
    (collection) =>
      collection.collection_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) &&
      !selectedCollections.some(
        (c) => c.collection_id === collection.collection_id
      )
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#232121" color="white">
        <ModalHeader>{isEditMode ? "Edit Bot" : "Create Bot"}</ModalHeader>
        <ModalCloseButton color="white" _hover={{ bg: "red.600" }} />
        <ModalBody>
          <FormControl>
            <FormLabel color="white">Bot Name</FormLabel>
            <Input
              value={botName}
              onChange={(e) => setBotName(e.target.value)}
              bg="#1c1c1c"
              color="white"
              _placeholder={{ color: "gray.400" }}
            />
          </FormControl>
          <FormControl mt={4} ref={inputRef} isInvalid={isCollectionError}>
            <FormLabel color="white">Collections</FormLabel>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              p={2}
              bg="#1c1c1c"
              borderRadius="md"
              cursor="pointer"
              onClick={handleInputClick}
              borderColor={isCollectionError ? "red.500" : "transparent"}
              borderWidth="1px"
            >
              {selectedCollections.map((collection) => (
                <Tag
                  size="md"
                  key={collection.collection_id}
                  borderRadius="full"
                  variant="solid"
                  colorScheme="red"
                  mr={2}
                  mb={2}
                >
                  <TagLabel>{collection.collection_name}</TagLabel>
                  <TagCloseButton
                    onClick={() =>
                      handleRemoveCollection(collection.collection_id)
                    }
                  />
                </Tag>
              ))}
              <Input
                placeholder={
                  selectedCollections.length > 0 ? "" : "Search collections"
                }
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                bg="#1c1c1c"
                color="white"
                _placeholder={{ color: "gray.400" }}
                border="none"
                _focus={{ border: "none" }}
                width="auto"
                flex="1"
              />
            </Box>
            {showDropdown && (
              <Box
                bg="#1c1c1c"
                mt={2}
                p={2}
                borderRadius="md"
                position="absolute"
                zIndex="10"
                width="100%"
                maxHeight="150px"
                overflowY="auto"
              >
                {filteredCollections.length === 0 ? (
                  <Text color="gray.400">No collections found</Text>
                ) : (
                  filteredCollections.map((collection) => (
                    <Text
                      key={collection.collection_id}
                      color="white"
                      cursor="pointer"
                      _hover={{ color: "red.600" }}
                      onClick={() => handleSelectCollection(collection)}
                    >
                      {collection.collection_name}
                    </Text>
                  ))
                )}
              </Box>
            )}
          </FormControl>
          <FormControl mt={4}>
            <FormLabel color="white">Prompt</FormLabel>
            <Textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              bg="#1c1c1c"
              color="white"
              _placeholder={{ color: "gray.400" }}
              minHeight="100px" // Adjust this height as needed
              resize="vertical" // Enable resizing
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            onClick={onClose}
            color="white"
            _hover={{ bg: "gray.800" }}
          >
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleSubmit} ml={3}>
            {isEditMode ? "Update Bot" : "Create Bot"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BotDialog;
