import { createReducer } from '@reduxjs/toolkit';
import { loginSuccess, loginFailure, logoutUser } from '../actions/authActions';
import { jwtDecode } from 'jwt-decode'; 
const initialState = {
  token: localStorage.getItem('token'),
  error: null,
};

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginSuccess, (state, action) => {
      const token = action.payload;
      const decoded = jwtDecode(token);  // Decode the token to get the data
      state.token = token;
      state.error = null;
      localStorage.setItem('token', token); // Store the entire token
    })
    .addCase(loginFailure, (state, action) => {
      state.token = null;
      state.error = action.payload;
      localStorage.removeItem('token');
    })
    .addCase(logoutUser, (state) => {
      state.token = null;
      state.error = null;
      localStorage.removeItem('token');
    });
});

export default authReducer;
