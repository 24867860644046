import React from "react";
import {
  Box,
  VStack,
  Flex,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, HamburgerIcon } from "@chakra-ui/icons";

const BotsList = ({
  bots,
  fetchBotConversation,
  handleDeleteBot,
  handleEditBot,
  handleNewBot,
  collapsed,
}) => {
  return (
    <Box
      width={collapsed ? "0" : "19rem"}
      bg="#1c1c1c"
      overflow="hidden"
      transition="width 0.3s"
      padding={collapsed ? "0rem" : "1rem"}
    >
      {!collapsed && (
        <VStack spacing={4}>
          <Button
            onClick={handleNewBot}
            colorScheme="red"
            bg="red.600"
            _hover={{ bg: "red.700" }}
            width="full"
            color="white"
          >
            New Bot
          </Button>
          {bots.map((bot) => (
            <Flex key={bot.bot_token} width="100%" alignItems="center">
              <Box flex="1">
                <Button
                  flex="1"
                  justifyContent="flex-start"
                  onClick={() => fetchBotConversation(bot.bot_token)}
                  whiteSpace="nowrap"
                  maxWidth="21rem"
                  textAlign="left"
                  bg="#1c1c1c"
                  color="white"
                  _hover={{ bg: "gray.800" }}
                >
                  {bot.bot_name.length > 20
                    ? `${bot.bot_name.substring(0, 20)}...`
                    : bot.bot_name}
                </Button>
              </Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  variant="outline"
                  aria-label="Options"
                  color="white"
                  bg="rgb(0 0 0 / 17%)"
                  _hover={{ bg: "gray.800" }}
                />
                <MenuList bg="#1c1c1c" borderColor="gray.700">
                  <MenuItem
                    icon={<EditIcon />}
                    onClick={() => handleEditBot(bot.bot_token)}
                    _hover={{ bg: "red.600", color: "rgba(0, 0, 0, 0.9)" }}
                    background={"#1c1c1c"}
                    color={"white"}
                  >
                    Edit Bot
                  </MenuItem>
                  <MenuItem
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteBot(bot.bot_token)}
                    _hover={{ bg: "red.600", color: "rgba(0, 0, 0, 0.9)" }}
                    background={"#1c1c1c"}
                    color={"white"}
                  >
                    Delete Bot
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default BotsList;
