// src/apiService.js
import axios from "axios";
import store from "./store/store"; // Import the Redux store to get the token

// const API_BASE_URL = "http://127.0.0.1:5005"; // Replace with your API base URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token;

    // Skip the token for searchWithBotToken API calls
    if (!config.url.includes("/search_with_bot_token") && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Authentication APIs
export const login = (credentials) => api.post("/auth/login", credentials);
export const register = (data) => api.post("/auth/register", data);

// Public Knowledge Base APIs
export const getPublicCollections = () =>
  api.get("/public_knowledge_base/collections");
export const createPublicCollection = (data) =>
  api.post("/public_knowledge_base/create_collection", data);
export const deletePublicCollection = (id) =>
  api.delete(`/public_knowledge_base/delete/${id}`);
export const listPublicKnowledgeBase = () =>
  api.get("/public_knowledge_base/list");
export const searchPublicKnowledgeBase = (query) =>
  api.get(`/public_knowledge_base/search?query=${query}`);
export const uploadPublicKnowledgeBase = (formData) =>
  api.post("/public_knowledge_base/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// Private Knowledge Base APIs
export const getPrivateCollections = () =>
  api.get("/knowledge_base/collections");
export const createPrivateCollection = (data) =>
  api.post("/knowledge_base/create_collection", data);
export const deletePrivateCollection = (id) =>
  api.delete(`/knowledge_base/delete/${id}`);
export const listPrivateKnowledgeBase = () => api.get("/knowledge_base/list");
export const searchPrivateKnowledgeBase = (query) =>
  api.get(`/knowledge_base/search?query=${query}`);
export const uploadPrivateKnowledgeBase = (data) =>
  api.post("/knowledge_base/upload", data);
export const getDocumentsByCollection = (collectionId) =>
  api.get(`/knowledge_base/list?collection_id=${collectionId}`);
export const deleteDocument = (documentId) =>
  api.delete(`/knowledge_base/delete?document_id=${documentId}`);
export const uploadDocument = (formData) =>
  api.post("/knowledge_base/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// Conversation APIs
export const getAllConversations = () =>
  api.get("/knowledge_base/get_all_conversations");
export const getConversation = (conversation_id) =>
  api.get("/knowledge_base/get_conversation", { params: { conversation_id } });
export const searchWithConversation = (
  collection_ids,
  conversation_id,
  query
) => {
  return api.post(`/knowledge_base/search_with_conversation`, {
    query,
    collection_ids,
    conversation_id,
  });
};

export const deleteConversation = (conversationId) =>
  api.delete(
    `/knowledge_base/delete_conversation?conversation_id=${conversationId}`
  );

// Fetch all bot tokens created by the authenticated user
export const getBotTokens = () => {
  return api.get("/auth/get_bot_tokens");
};

// API call to generate bot token
export const generateBotToken = (payload) => {
  return api.post("/auth/generate_bot_token", payload); // Ensure `api` is correctly configured with Axios or Fetch
};

// Fetch details of a specific bot token
export const getBotTokenDetails = (bot_token) => {
  return api.get(`/auth/get_bot_token_details`, {
    params: { bot_token },
  });
};

// Update bot details
export const updateBotToken = (payload) => {
  return api.put("/auth/update_bot_token", payload);
};

// Function to search with bot token
export const searchWithBotToken = (data) => {
  return api.post(
    `/knowledge_base/search_with_bot_token`,
    {
      query: data.query, // The query is sent in the payload
    },
    {
      headers: {
        Authorization: data.bot_token, // Send the bot token directly
        "Content-Type": "application/json",
      },
    }
  );
};

// Function to delete a bot by token
export const deleteBotToken = (botToken) => {
  return api.delete(`/auth/delete_bot_token`, {
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      bot_token: botToken, // Pass the bot token in the body for deletion
    },
  });
};
