import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Card,
  CardBody,
  Button,
  Spinner,
  Textarea,
  useToast,
  useBreakpointValue, // Import this hook
} from "@chakra-ui/react";
import MessagesDisplay from "./MessagesDisplay";
import { searchWithBotToken } from "../../apiService"; // Ensure this is the correct API call
import { useLocation } from "react-router-dom";

const BotEmbedConversation = () => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [botToken, setBotToken] = useState(null);
  const toast = useToast();
  const location = useLocation();

  // Extract the bot token from the URL when the page loads
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const botTokenFromURL = params.get("bot_token");
    if (botTokenFromURL) {
      setBotToken(botTokenFromURL);
      sendMessageWithoutShowingHi("Hi", botTokenFromURL);
    } else {
      toast({
        title: "Error",
        description: "Bot token is required to load the conversation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [location]);

  // Function to send "Hi" without showing it
  const sendMessageWithoutShowingHi = async (messageText, botToken) => {
    try {
      const response = await searchWithBotToken({
        query: messageText,
        bot_token: botToken,
      });

      const cleanedSuggestions = response.data?.suggestions
        ?.map((suggestion) => suggestion.replace(/^\d+\.\s*/, ""))
        .filter(Boolean);

      const botMessage = {
        content: response.data?.response || "Error: No response from bot",
        role: "system",
        suggestions: cleanedSuggestions || [],
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsTyping(false);
      setIsSending(false);
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error",
        description: "Failed to send message. Try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsSending(false);
    }
  };

  // Function to send a message and show it
  const handleSendMessage = async (messageText) => {
    const messageToSend = messageText || currentMessage.trim();
    if (
      !messageToSend ||
      (messages.length &&
        messages[messages.length - 1].content === messageToSend)
    )
      return;

    setIsSending(true);
    const userMessage = { content: messageToSend, role: "user" };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setCurrentMessage("");

    try {
      const response = await searchWithBotToken({
        query: messageToSend,
        bot_token: botToken,
      });

      const cleanedSuggestions = response.data?.suggestions
        ?.map((suggestion) => suggestion.replace(/^\d+\.\s*/, ""))
        .filter(Boolean);

      const botMessage = {
        content: response.data?.response || "Error: No response from bot",
        role: "system",
        suggestions: cleanedSuggestions || [],
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsTyping(false);
      setIsSending(false);
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error",
        description: "Failed to send message. Try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsSending(false);
    }
  };

  // Adjust width for Textarea based on screen size
  const inputWidth = useBreakpointValue({ base: "100%", md: "60%" });

  return (
    <Flex height="100vh" flexDirection="column" backgroundColor="white">
      {/* Navbar (fixed at the top) */}
      <Box
        as="nav"
        backgroundColor="teal"
        color="white"
        p={4}
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="1000"
      >
        <Flex justifyContent="center">
          <Box fontSize="lg" fontWeight="bold">
            Lux Health Clinic
          </Box>
        </Flex>
      </Box>

      {/* Main content with scrolling chat area */}
      <Box flex="1" width="100%" maxWidth="100%" height="90vh" p={4} overflow="hidden">
        <Card height="100%" bg="white" color="black" overflow="hidden">
          <CardBody
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            overflow="hidden"
          >
            <MessagesDisplay
              messages={messages}
              isTyping={isTyping}
              handleSendMessage={handleSendMessage}
            />

            <Flex mt={4} justifyContent="center" width="100%">
              <Flex width={inputWidth} alignItems="center">
                <Textarea
                  value={currentMessage}
                  onChange={(e) => setCurrentMessage(e.target.value)}
                  placeholder="Ask"
                  bg="#f2f2f2"
                  color="black"
                  _placeholder={{ color: "gray.600" }}
                  flex="1"
                  mr={2}
                  minHeight="2rem"
                  resize="none"
                  boxSizing="border-box"
                  padding="8px"
                />
                <Button
                  onClick={() => handleSendMessage(currentMessage)}
                  colorScheme="teal"
                  isDisabled={isSending}
                  rightIcon={isSending ? <Spinner size="sm" /> : null}
                  minHeight="2rem"
                  boxSizing="border-box"
                >
                  {isSending ? "Sending" : "Send"}
                </Button>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </Flex>
  );
};

export default BotEmbedConversation;
