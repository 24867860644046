export const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value) || 'Invalid email address';
  };
  
  export const validatePasswordStrength = (value) => {
    const strongPasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return strongPasswordPattern.test(value) || 'Password must contain at least 8 characters, including uppercase, lowercase, and numbers';
  };
  
  export const validateEmailOrUsername = (value) => {
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const isUsername = /^[a-zA-Z0-9]+$/.test(value);
    if (!isEmail && !isUsername) {
      return 'Invalid email or username';
    }
    return true;
  };
  