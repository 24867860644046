import React from 'react';
import { HStack, Textarea, Button, Spinner } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const MessageInput = ({ currentMessage, setCurrentMessage, handleSendMessage, isSending }) => {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <HStack justifyContent={"center"}>
      <Textarea
        value={currentMessage}
        onChange={(e) => setCurrentMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Ask any question"
        size="sm"
        resize="none"
        rows={2}
        borderRadius="md"
        bg="#232121"
        color="white"
        _placeholder={{ color: 'gray.400' }}
        height="40px"
        width={"36rem"}
      />
      <Button
        onClick={handleSendMessage}
        colorScheme="red"
        bg="red.600"
        _hover={{ bg: "red.700" }}
        rightIcon={isSending ? <Spinner size="sm" /> : <ArrowForwardIcon />}
        isDisabled={isSending}
        height="40px"
      >
        {isSending ? 'Sending' : 'Send'}
      </Button>
    </HStack>
  );
};

export default MessageInput;
